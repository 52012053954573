.carousel-wrapper {
    position: relative; 
  }
  
  .carousel-container {
    position: relative;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.3s;
    width: 100%;  
  }
  
  .card {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
  }
  
  .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 2px 2px 2px #000;
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
  
  .button:nth-child(1) {
    left: 0%;
  }
  .button:nth-child(1) img {
    margin-right: 5px;
    width: 14px;
  }
  
  .button:nth-child(3) {
    right: 0%;
  }
  .button:nth-child(3) img {
    margin-left: 5px;
    width: 14px;
  }
  
  .dots {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: #000;
  }
  