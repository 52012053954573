.actionButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 325px;
    height: 57px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FEC737;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: 0.5s;
}

.actionButton:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.actionButton label {
    cursor: pointer;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'AvenirNextBold';
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}

.pastil {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    flex-shrink: 0;
    fill: #F8493A;
    background-color: #F8493A;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media (max-width: 950px) {
    .actionButton {
        width: 245px;
        height: 50px;
        border-radius: 12px;
    }
    .actionButton label {
        font-size: 16px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    .pastil {
        top: -4px;
        right: -4px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25));
    }
}