@font-face {
  font-family: "AvenirNextHeavy";
  src: local("AvenirNextHeavy"),
   url("./assets/fonts/avenirs/AvenirNext-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNextDemiBold";
  src: local("AvenirNextDemiBold"),
   url("./assets/fonts/avenirs/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNextBold";
  src: local("AvenirNextBold"),
   url("./assets/fonts/avenirs/AvenirNext-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Ballo";
  src: local("Ballo"),
   url("./assets/fonts/balooTamma/BalooTamma-Regular.ttf") format("truetype");
}

.container {
  background-color: #FEFEFE;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionWithBg {
  background-image: url('./assets/bgs/popkodeBG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 94%;
  height: 420px;
  margin-top: 80px;
}

.section_2 {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mvpDescription {
  background-image: url('./assets/bgs/home_bg_1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mvpDescTxt_1 {
  margin: 0%;
  font-family: 'AvenirNextDemiBold';
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 22px;
  letter-spacing: -1.76px;
}
.mvpDescTxt_2 {
  margin: 0%;
  font-family: 'AvenirNextDemiBold';
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -3.2px;
  width: 60%;
  text-align: center;
  margin-bottom: 20px;
}
.mvpDescTxt_2 label {
  font-family: 'AvenirNextHeavy';
}

.reactToAndroiAndIosApp {
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.reactNativeBG {
  background-image: url('./assets/bgs/reactBG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 49%;
}
.reactNativeBG_EN {
  background-image: url('./assets/bgs/reactBG_EN.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 49%;
}

.androidAndIosBG {
  background-image: url('./assets/bgs/iosAndAndroidBG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 49%;
}
.androidAndIosBG_EN {
  background-image: url('./assets/bgs/iosAndAndroidBG_EN.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 49%;
}


.footerContainer {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerContainer > div {
  width: 50%;
}

.footerContainer > img {
  flex-shrink: 0;
}

.popkodeTxt {
  margin: 0%;
  text-align: center;
  font-family: 'Ballo';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -5px;
}

.footerLogoContainer {
  display: flex;
  justify-content: center;
}
.footerLogo {
  height: 100px;
}

.adressContainer {
  margin-top: -20px;
}

.adresss {
  margin: 0%;
  font-family: 'AvenirNextDemiBold';
  font-size: 25x;
  text-align: center;
}

.methodTxtContainer {
  margin-top: 100px;
  padding-left: 160px;
  padding-right: 160px;
}

.methodTitle {
  margin: 0%;
  color: #000;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'AvenirNextBold';
  font-size: 50px;
  font-style: normal;
  line-height: 60px;
  letter-spacing: -4px;
  padding-left: 230px;
  padding-right: 230px;
}
.methodTxtLabel {
  text-shadow: none;
  background: #F8493A;
  background: linear-gradient(to right, #F8493A 25%, #FEC737 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'AvenirNextHeavy';
}
.attrayantTxtLabel {
  text-shadow: none;
  background: #000000;
  background: linear-gradient(to bottom right, #000000 8%, #FEFBFB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'AvenirNextHeavy';
}

.methodDescription {
  margin: 0%;
  /* margin-top: 25px;
  padding-left: 170px;
  padding-right: 170px; */
  margin-top: 25px;
  /* margin-left: 100px;
  margin-right: 100px; */
  color: #000;
  text-align: justify;
  font-family: 'AvenirNextDemiBold';
  font-size: 25px;
  line-height: 35px;
  letter-spacing: -2px;
}
.methodDescription label {
  font-family: 'AvenirNextBold';
}

@media (max-width: 950px) {

  .footerContainer {
    flex-direction: column;
    max-width: 100%;
    overflow-x: hidden;
  }

  .logoMinStyle {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .sectionWithBg {
    background-image: url('./assets/bgs/popkodeBG_Mobil.png');
    width: 100%;
    height: 350px;
    margin-top: 80px;
  }

  .methodTxtContainer {
    margin-top: 80px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .methodTitle {
    font-size: 30px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    line-height: 28px;
    letter-spacing: -2px;
    padding-left: 0px;
    padding-right: 0px;
}

  .methodDescription {
    font-size: 18px;
    margin: 0%;
    line-height: 23px;
    letter-spacing: 0px;
    margin-top: 40px;
  }

  .methodDescriptionListContainer {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }

  .methodDescriptionListTxt {
    margin: 0%;
    margin-bottom: 5px;
    color: #000;
    font-family: 'AvenirNextBold';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: -0.6px;
  }

  .section_2 {
    margin-top: 80px;
  }

  .mvpDescription {
    background-image: url('./assets/bgs/home_bg_1_mobil.png');
    height: 350px;
    width: 100%;
  }
  .mvpDescTxt_2 {
    width: 90%;
    font-size: 24px;
    line-height: 25px;
    letter-spacing: -1.33px;
  }

  .reactToAndroiAndIosApp {
    width: 100%;
    margin-top: -30px;
  }
  .reactNativeBG {
    background-image: url('./assets/bgs/reactBGMobil.png');
    background-size: contain;
    height: 350px;
    width: 48.5%;
  }

  .androidAndIosBGMobile {
    background-image: url('./assets/bgs/androidAndIosBGMobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .androidAndIosBGMobileDesciption {
    width: 80%;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -1.33px;
    margin: 0%;
    color: #FFF;
    text-align: justify;
    font-family: 'AvenirNextDemiBold';
  }

  .androidAndIosBGMobileDesciption label {
    font-family: 'AvenirNextHeavy';
  }

  .ReactNativeLogo {
    width: 70px;
    margin-top: 10px;
  }
  
  .GroupAndroidAndApple {
    margin-top: 10px;
    width: 100px;
  }

}
