
@font-face {
    font-family: "AvenirNextHeavy";
    src: local("AvenirNextHeavy"),
     url("../../assets/fonts/avenirs/AvenirNext-Heavy.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextDemiBold";
    src: local("AvenirNextDemiBold"),
     url("../../assets/fonts/avenirs/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextBold";
    src: local("AvenirNextBold"),
     url("../../assets/fonts/avenirs/AvenirNext-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "balooTamma";
    src: local("balooTamma"),
     url("../../assets/fonts/balooTamma/BalooTamma-Regular.ttf") format("truetype");
}

.teamContainer {
    flex: 1;
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.memberContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 56px;
    margin-right: 56px;
}

.avatar {
    margin-bottom: 20px;
}

.memberNameTxt {
    margin: 0%;
    font-family: 'balooTamma';
    font-size: 30px;
    line-height: 170.577%; 
}

.memberFunction {
    margin: 0%;
    margin-top: 12px;
    font-family: 'AvenirNextHeavy';
    font-size: 30px;
    line-height: 0%;
    letter-spacing: -2.4px;
    font-style: normal;
    margin-bottom: 45px;
}

.memberDescriptionTxt {
    margin: 0%;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-family: 'AvenirNextDemiBold';
}

.letterTxt {
    font-family: 'AvenirNextHeavy';
    font-size: 30px;
}

.memberDescriptionTxt label {
    font-family: 'AvenirNextBold';
}

.memberDesciptionContainer {
    width: 350px;
    padding: 48px;
    padding-top: 33px;
    padding-bottom: 33px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.05);
}

.SkillslogosImg {
    margin-top: 10px;
}

.showMoreContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 350px;
    height: 54px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.05);
}

.showMoreContainer label {
    font-family: 'AvenirNextDemiBold';
    margin-right: 2px;
    cursor: pointer;
    font-size: 20px;
    line-height: normal;
    font-style: normal;
}

@media (max-width: 950px) {

    .teamContainer {
        flex-direction: column;
        align-items: center;
    }

    .memberContainer {
        margin-left: 10px;
        margin-right: 10px;
    }

    .avatar {
        width: 225px;
    }

    .memberNameTxt {
        font-size: 25px;
        line-height: 170.577%; 
    }
    
    .memberFunction {
        margin-top: 12px;
        font-size: 25px;
        line-height: 0%;
        letter-spacing: -2.4px;
        margin-bottom: 30px;
        border-radius: 17px;
    }

    .memberDesciptionContainer {
        width: 300px;
        padding: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .letterTxt {
        font-size: 25px;
    }
    .memberDescriptionTxt {
        font-size: 16px;
        line-height: normal;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .SkillslogosImg {
        width: 240px;
        margin-top: 10px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
    }

    .arrowDown {
        width: 15px;
    }

    .showMoreContainer {
        width: 300px;
        border-radius: 20px;
    }
    .showMoreContainer label {
        font-size: 16px;
    }

}
