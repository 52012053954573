@font-face {
    font-family: "AvenirNextHeavy";
    src: local("AvenirNextHeavy"),
     url("../../assets/fonts/avenirs/AvenirNext-Heavy.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextDemiBold";
    src: local("AvenirNextDemiBold"),
     url("../../assets/fonts/avenirs/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextBold";
    src: local("AvenirNextBold"),
     url("../../assets/fonts/avenirs/AvenirNext-Bold.ttf") format("truetype");
}

.ourWorkContainer {
    flex: 1;
    margin-top: 100px;
    padding-left: 170px;
    padding-right: 170px;
}

.ourWorkTitle {
    text-align: center;
    margin: 0%;
    font-family: 'AvenirNextBold';
    font-size: 50px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-style: normal;
    line-height: 100px;
    letter-spacing: -4px;
}

.concevoir {
    color: #F8493A;
    font-family: 'AvenirNextHeavy';
}

.coder {
    color: #FEC737;
    font-family: 'AvenirNextHeavy';
}

.ourWorkTextsContainer {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ourWorkText {
    text-align: justify;
    font-family: "AvenirNextDemiBold";
    font-size: 25px;
    margin: 0%;
    line-height: 35px;
    letter-spacing: -2px;
}

.ourWorkText label {
    font-family: "AvenirNextBold";
}

@media (max-width: 950px) {
    .ourWorkContainer {
        padding-left: 10px;
        padding-right: 10px;
    }

    .ourWorkTextMobil {
        margin-top: 40px;
    }

    .ourWorkTitle {
        font-size: 30px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        line-height: 35px;
        letter-spacing: -2px;
        padding-left: 60px;
        padding-right: 60px;
    }
    
    .ourWorkTextsContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ourWorkText {
        font-size: 18px;
        margin: 0%;
        line-height: 23px;
        letter-spacing: 0px;
    }
}
