@font-face {
    font-family: "AvenirNextDemiBold";
    src: local("AvenirNextDemiBold"),
     url("../../assets/fonts/avenirs/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextBold";
    src: local("AvenirNextBold"),
     url("../../assets/fonts/avenirs/AvenirNext-Bold.ttf") format("truetype");
}

.headerContainer {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/bgs/home_bg_0.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.headerContainer_EN {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/bgs/home_bg_0_EN.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}
.navContainer--scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 7px;
    padding-top: 7px;
    background-color: #FEFEFE;
    z-index: 1000;
    box-shadow: 0px 15px 30px 5px rgba(0,0,0,0.1);

    .contactUsButton {
        border-radius: 12px;
        padding: 12px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

.logoHeader {
    margin-left: 40px;
    cursor: pointer;
    transition: 0.5s;
}
.logoHeader:hover {
    transition: 0.5s;
    transform: scale(1.1);
}

.navItemsContainer {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.navItem {
    margin-right: 100px;
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-family: 'AvenirNextDemiBold';
    transition: 500ms;
}
.navItem:hover {
    /* text-decoration: underline; */
    font-family: 'AvenirNextBold';
    transition: 500ms;
    transform: scale(1.05);
}

.contactUsButton {
    background-color: #000;
    border-radius: 12px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    transition: 0.5s;
}
.contactUsButton:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.contactUsButton label {
    color: #FFF;
    font-family: 'AvenirNextBold';
    font-size: 18px;
    cursor: pointer;
}

.actionButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30%;
    padding-right: 2%;
}

.actionButtonContainer--scrolled {
    padding-top: 90px;
}

.actionButton_ANDROID_Container {
    margin-top: 120%;
    margin-left: 18%;
}
.actionButton_ANDROID_Container--scrolled {
    margin-top: 105%;
}

@media (max-width: 950px) {

    .headerContainer {
        background-image: url('../../assets/bgs/home_bg_0_mobil.png');
    }
    .headerContainer_EN {
        background-image: url('../../assets/bgs/home_bg_0_mobil_EN.png');
    }

    .navContainer {
        padding-top: 10px;
    }

    .logoMobil {
        padding-left: 10px;
    }

    .menuIcon {
        padding-right: 8px;
    }

    .actionButtonContainer {
        margin-top: 110%;
        padding-right: 0%;
    }

    .navContainer--scrolled {
        padding-bottom: 0px;
    }

    .modalContainer {
        top: 0%;
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: #00000090;
        display: flex;
    }
    .closeModalMenuContainer {
        width: 35%;
        height: 100%;
    }
    .mobilMenuContainer {
        flex: 1;
        padding-right: 20px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .navItemsMobilContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .navItemMobil {
        color: #000;
        font-size: 18px;
        font-family: 'AvenirNextDemiBold';
        margin-bottom: 50px;
    }
    .logoHeader {
        margin-left: 0px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

}

@media (max-width: 380px) {

    .actionButtonContainer {
        margin-top: 100%;
    }

}

/* Grand ecran */
@media (min-width: 1700px) {

    .actionButtonContainer {
        margin-top: 30%;
    }

}
