@font-face {
    font-family: "AvenirNextHeavy";
    src: local("AvenirNextHeavy"),
     url("../../assets/fonts/avenirs/AvenirNext-Heavy.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextDemiBold";
    src: local("AvenirNextDemiBold"),
     url("../../assets/fonts/avenirs/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNextBold";
    src: local("AvenirNextBold"),
     url("../../assets/fonts/avenirs/AvenirNext-Bold.ttf") format("truetype");
}

.methodContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.methodRowContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.methodSubContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
}
.methodItemHeaderContainer {
    display: flex;
    align-items: center;
}
.methodItemContainer_1 {
    position: relative;
    flex: 1;
    margin: 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 20px;
    border: solid 20px #000;
    border-radius: 50px;
}
.methodItemillustrationClass_1 {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000028;
    width: 84px;
    height: 84px;
    border-radius: 84px;
}
.methodItemillustrationClass_1 div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: #000;
    color: #fff;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    font-family: "AvenirNextBold";
}
.stepClass_1 {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #000;
    color: #fff;
    font-size: 35px;
    line-height: normal;
    letter-spacing: -2.8px;
    width: 100px;
    height: 92px;
    flex-shrink: 0;
    border-radius: 0px 50px 0px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "AvenirNextBold";
}
.stepClass_1 label {
    margin-top: -15px;
    margin-right: -15px;
}

.methodItemContainer_2 {
    position: relative;
    flex: 1;
    margin: 20px;
    background: #FFA4A4;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    border: solid 20px #fff;
    border-radius: 50px;
}
.methodItemillustrationClass_2 {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff28;
    width: 84px;
    height: 84px;
    border-radius: 84px;
}
.methodItemillustrationClass_2 div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: #fff;
    color: #FFA4A4;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    font-family: "AvenirNextBold";
}
.stepClass_2 {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #fff;
    color: #FFA4A4;
    font-size: 35px;
    line-height: normal;
    letter-spacing: -2.8px;
    width: 100px;
    height: 92px;
    flex-shrink: 0;
    border-radius: 0px 50px 0px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "AvenirNextBold";
}
.stepClass_2 label {
    margin-top: -15px;
    margin-right: -15px;
}

.methodItemContainer_3 {
    position: relative;
    flex: 1;
    margin: 20px;
    background: #FFF0C8;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 20px;
    border: solid 20px #000;
    border-radius: 50px;
}
.methodItemillustrationClass_3 {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000028;
    width: 84px;
    height: 84px;
    border-radius: 84px;
}
.methodItemillustrationClass_3 div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: #000;
    color: #FFF0C8;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    font-family: "AvenirNextBold";
}
.stepClass_3 {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #000;
    color: #FFF0C8;
    font-size: 35px;
    line-height: normal;
    letter-spacing: -2.8px;
    width: 100px;
    height: 92px;
    flex-shrink: 0;
    border-radius: 0px 50px 0px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "AvenirNextBold";
}
.stepClass_3 label {
    margin-top: -15px;
    margin-right: -15px;
}

.step_1_title {
    margin: 0%;
    font-family: 'AvenirNextBold';
    font-size: 33px;
    color: #000;
    line-height: normal;
    letter-spacing: -2.64px;
}

.step_2_title {
    margin: 0%;
    font-family: 'AvenirNextBold';
    font-size: 33px;
    color: #fff;
    line-height: normal;
    letter-spacing: -2.64px;
}

.step_3_title {
    margin: 0%;
    font-family: 'AvenirNextBold';
    font-size: 33px;
    color: #000;
    line-height: normal;
    letter-spacing: -2.64px;
}

.descriptionMethodItemContainer {
    margin: 20px;
}

.descriptionMethodItemContainer ul {
    margin: 0%;
    padding: 0%;
}
.descriptionTxt_step_1 {
    margin: 0%;
    font-size: 20px;
    font-family: 'AvenirNextBold';
    line-height: normal;
    letter-spacing: -1.6px;
}
.descriptionLiTxt_step_1 {
    font-family: 'AvenirNextDemiBold';
    font-size: 20px;
    line-height: normal;
    letter-spacing: -1.6px;
}

.descriptionTxt_step_1_bis {
    margin: 0%;
    font-family: 'AvenirNextDemiBold';
    font-size: 20px;
    line-height: normal;
    letter-spacing: -1.6px;
}
.descriptionTxt_step_1_bis label {
    font-family: 'AvenirNextBold';
}

.descriptionTxt_step_2 {
    margin: 0%;
    font-family: 'AvenirNextDemiBold';
    color: #fff;
    font-size: 20px;
    line-height: normal;
    letter-spacing: -1.6px;
}
.descriptionTxt_step_2 label {
    font-family: 'AvenirNextBold';
}

@media (max-width: 950px) {

    .swipperContainer {
        width: 100%;
        overflow: hidden;
        margin: 0%
    }

    .methodItemContainer_1 {
        margin: 20px;
        box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 20px;
        border: solid 20px #000;
        border-radius: 50px;
    }
    .methodItemillustrationClass_1 {
        margin-right: 10px;
        width: 65px;
        height: 65px;
        border-radius: 65px;
    }
    .methodItemillustrationClass_1 div {
        font-size: 25px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .stepClass_1 {
        right: -4px;
        top: -4px;
        color: #fff;
        font-size: 25px;
        line-height: normal;
        letter-spacing: -2.8px;
        width: 65px;
        height: 55px;
        flex-shrink: 0;
        border-radius: 0px 50px 0px 100px;
    }
    .stepClass_1 label {
        margin-top: -15px;
        margin-right: -15px;
    }
    
    .methodItemContainer_2 {
        flex: 1;
        margin: 20px;
        box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 20px;
        border: solid 20px #fff;
        border-radius: 50px;
    }
    .methodItemillustrationClass_2 {
        margin-right: 10px;
        width: 65px;
        height: 65px;
        border-radius: 65px;
    }
    .methodItemillustrationClass_2 div {
        font-size: 25px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .stepClass_2 {
        right: -4px;
        top: -4px;
        font-size: 25px;
        line-height: normal;
        letter-spacing: -2.8px;
        width: 65px;
        height: 55px;
        flex-shrink: 0;
        border-radius: 0px 50px 0px 100px;
    }
    .stepClass_2 label {
        margin-top: -15px;
        margin-right: -15px;
    }
    
    .methodItemContainer_3 {
        margin: 20px;
        box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 20px;
        border: solid 20px #000;
        border-radius: 50px;
    }
    .methodItemillustrationClass_3 {
        margin-right: 10px;
        width: 65px;
        height: 65px;
        border-radius: 65px;
    }
    .methodItemillustrationClass_3 div {
        font-size: 25px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    .stepClass_3 {
        right: -4px;
        top: -4px;
        font-size: 25px;
        line-height: normal;
        letter-spacing: -2.8px;
        width: 65px;
        height: 55px;
        flex-shrink: 0;
        border-radius: 0px 50px 0px 100px;
    }
    .stepClass_3 label {
        margin-top: -15px;
        margin-right: -15px;
    }
    
    .step_1_title {
        font-size: 22px;
        line-height: normal;
        letter-spacing: -2.64px;
    }
    
    .step_2_title {
        font-size: 23px;
        line-height: normal;
        letter-spacing: -2.64px;
    }
    
    .step_3_title {
        font-size: 23px;
        line-height: normal;
        letter-spacing: -2.64px;
    }
    
    .descriptionMethodItemContainer {
        margin: 20px;
    }
    
    .descriptionMethodItemContainer ul {
        margin: 0%;
        padding: 0%;
    }
    .descriptionTxt_step_1 {
        margin: 0%;
        font-size: 16px;
        line-height: normal;
        letter-spacing: -1.6px;
    }
    .descriptionLiTxt_step_1 {
        font-size: 16px;
        line-height: normal;
        letter-spacing: -1.6px;
    }
    
    .descriptionTxt_step_1_bis {
        margin: 0%;
        font-size: 16px;
        line-height: normal;
        letter-spacing: -1.6px;
    }
    
    .descriptionTxt_step_2 {
        margin: 0%;
        color: #fff;
        font-size: 16px;
        line-height: normal;
        letter-spacing: -1.6px;
    }

}

