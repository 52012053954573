.position {
    position: fixed;
    bottom: 0%;
    left: 10px;
    z-index: 999999;
}

.buttonStyle {
    background-color: #FBFBFB;
    border: solid 1px #DEDEDE;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
}
.buttonStyle label {
    cursor: pointer;
}

.flagIcon {
    width: 20px;
    height: 15px;
    object-fit: cover;
    margin-right: 5px;
}

@media (max-width: 950px) {
    
}