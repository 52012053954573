.logoContainer {
    margin-top: -100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 200px;
    height: 100px;
    margin: 30px;
    object-fit: contain;
    max-width: 100%;
    overflow-clip-margin: content-box;
}

@media (max-width: 950px) {
    .logoContainer {
        margin-top: -100px;
    }
    
    .logo {
        width: 100px;
        height: 50px;
        margin: 10px;
    }
}
